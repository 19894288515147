@import "src/styles";

.numpad-display {
    @extend %flex-align-center;
    @extend %text-ellipsis;

    height: 2.75rem;
    width: 17rem;
    font-size: 2rem;
    color: var(--c-white);
    background: var(--c-sailor);
}
