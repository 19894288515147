:root {
    --c-rgba-white:                 255, 255, 255;

    --c-primary:                    #114D61;
    --c-secondary:                  #bdf5ff;
    --c-sky:                        #86C8DF;
    --c-ocean:                      #00ACF5;
    --c-sailor:                     #0D3C4B;
    --c-dark-blue:                  #2A6A7F;
    --c-dark-grey:                  #3e5063;
    --c-light-grey:                 #a1b1bb;
    --c-green:                      #28ae60;
    --c-white:                      #ffffff;
    --c-orange:                     #ff8100;
    --c-red:                        #ff1c25;

    --f-primary:                    'Open Sans', sans-serif;

    --fw-regular:                   300;
    --fw-medium:                    400;
    --fw-bold:                      700;
}
