@import "src/styles";

.select-shift {
    text-align: center;

    &__title {
        @include animate-scale-fade-in();
    }

    &__timer {
        margin: 2rem;
    }

    &__shift-buttons-wrapper {
        @include animate-scale-fade-in(1);
    }

    &__log-out-button {
        @extend %page-log-out-button-position;
    }
}