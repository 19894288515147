@import 'flex';

%button {
    @extend %flex-align-center;

    margin: 0;
    padding: 0;
    font: inherit;
    font-size: .9375rem;
    font-weight: var(--fw-regular);
    line-height: 1;
    color: var(--c-primary);
    cursor: pointer;
    border: none;
    background: none;

    &:hover,
    &:focus {
        outline: none;
    }

    &:disabled {
        color: var(--c-disabled-grey);
        cursor: not-allowed;
    }
}

%button--is-primary {
    @extend %flex-align-center;

    border-radius: 2px;
    padding: .625rem 1rem;
    color: var(--c-white);
    background: var(--c-orange);
    overflow: hidden;

    &:hover,
    &:focus {
        color: var(--c-white);
        background: var(--c-orange-dark);
    }

    &:disabled {
        color: var(--c-white);
        background: var(--c-placeholder);
    }
}

%button--is-circular {
    border: 2px solid var(--c-sailor);
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    font-size: 1.75rem;
    font-weight: var(--fw-regular);
    color: var(--c-white);

    &:active {
        color: var(--c-sailor);
    }
}

%button--is-rectangular {
    border: 2px solid var(--c-sailor);
    border-radius: .5rem;
    padding: 1.5rem;
    letter-spacing: .05rem;
    font-size: 1.75rem;
    font-weight: var(--fw-regular);
    color: var(--c-white);

    &:active {
        color: var(--c-sailor);
    }
}

%button--reset {
    border: none;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

%widget-button {
    @extend %button--reset;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid var(--c-border-grey);
    width: 100%;
    height: 2.5rem;
    padding: 0 .25rem 0 1.25rem;
    color: var(--c-primary);
    cursor: pointer;
    background: var(--c-white);

    &[draggable="true"] {
        cursor: move;
    }

    &:hover,
    &--is-active {
        border-color: var(--c-primary);
    }
}
