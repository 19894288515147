@import "src/styles";

.valk-logo {
    position: relative;
    box-sizing: content-box;
    padding: 3.75rem 2.5rem;

    &__img {
        width: 100%;
    }
}
