@import "src/styles";

.master-login-screen {

    &--is-failed &__title {
        font-weight: var(--fw-medium);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__dots,
    &__numpad-input {
        margin-top: 3rem;
    }
}
