@import "src/styles";

$component: numpad-button;

.#{$component} {
    @extend %button;

    border: .125rem solid var(--c-sailor);
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    font-size: 1.75rem;
    color: var(--c-white);

    &:hover {
        color: var(--c-white);
    }

    &:active {
        background-color: var(--c-secondary);
        border-color: var(--c-secondary);
        color: var(--c-primary);
    }

    &--is-backspace,
    &--is-submit {
        border: 0;

        &:active {
            background-color: transparent;
            color: var(--c-sailor);
        }
    }

    &--is-submit {
        font-size: 1.125rem;
    }

    .#{$component}__backspace-icon {
        width: 2.75rem;
        height: 2.75rem;
    }
}
