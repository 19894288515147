@import "src/styles";

.pause-screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        margin-top: 3rem;
    }

    &__buttons-wrapper {
        display: flex;
        margin-top: 3.5rem;
    }

    &__button {
        @extend %button--is-circular;

        @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
                @include animate-scale-in($i);
            }
        }
    }

    &__button + &__button {
        margin-left: 1.25rem;
    }
}
