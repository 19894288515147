@import "animations";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    font-size: 16px;
}

h1 {
    line-height: 1.2;
    letter-spacing: .05rem;
    font-size: 1.6875rem;
    font-weight: var(--fw-regular);
    color: var(--c-white);
}
