@import "src/styles";

.loading-screen {
    @extend %flex-align-center;

    flex-grow: 1;
    padding-bottom: 3rem;

    &__loader {
        @extend %loading-spinner;
    }
}
