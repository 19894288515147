@import "src/styles";

.image-cache {

    &__background {
        background-image: url('../../images/background.svg');
    }

    &__play {
        background-image: url('../../images/play.svg');
    }

    &__stop {
        background-image: url('../../images/stop.svg');
    }
}
