@import "src/styles";

.comment-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title {
        margin-top: 3rem;
    }

    &__textarea {
        border: 2px solid var(--c-sky);
        border-radius: 2px;
        margin-top: 3rem;
        padding: .75rem;
        width: 100%;
        max-width: 40.625rem;
        height: 10rem;
        font-size: 1.25rem;
        background: var(--c-dark-blue);
        color: var(--c-white);

        &::placeholder {
            color: rgba(255, 255, 255, .3);
        }
    }

    &__buttons-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
    }

    &__submit-button {
        @extend %button--is-rectangular;

        border: 0;
        color: var(--c-white);
        background: var(--c-orange);
        font-size: 1.325rem;

        &:disabled {
            opacity: .7;
        }
    }

    &__back-button {
        @extend %page-back-button-position;
    }

    &__skip-button {
        margin-left: 2rem;
    }
}
