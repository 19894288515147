@import "src/styles";

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
    min-height: 100svh;
    padding: 1rem;
    padding-bottom: 3rem;
    text-align: center;
    color: var(--c-white);
    background: var(--c-primary);
    background-image: url('../../images/background.svg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;

    &__valk-logo {
        margin: 0 auto;
        width: 9.125rem;
    }

    &__version-number {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: .875rem;
        opacity: .4;
        pointer-events: none;
    }

    &__language-selector {
        margin-top: 3rem;
    }
}
