@import "src/styles";

.login-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
        @include animate-scale-fade-in();
    }

    &__dots,
    &__numpad-display,
    &__numpad-input {
        margin-top: 3rem;
    }

    &__numpad {
        @include animate-scale-fade-in(1);

        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
