@import "src/styles";

.timer-clock {
    font-size: 4.5rem;

    &__label {
        display: inline-flex;
        flex-direction: row;

        &:after {
            content: ':';
            margin: 0 .75rem;
        }

        &:last-child:after {
            content: '';
            margin: 0;
        }
    }
}