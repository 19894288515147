@import "src/styles";

.log-out-button {
    font-size: 1rem;
    letter-spacing: .05rem;
    color: var(--c-white);

    &:active,
    &:active &__icon {
        color: var(--c-sailor);
    }

    &__icon {
        @include icon-size(1.75rem);
    }
}
