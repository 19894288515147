@import "src/styles";

.goodbye-screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__goodbye {
        animation: scaleFadeIn 2s cubic-bezier(0, .54, .39, 1);
        animation-fill-mode: forwards;
        transform: scale(.8);
        transform-origin: 50% 50%;
        margin-top: 7rem;
        font-size: 3rem;
        font-style: italic;
        opacity: 0;
    }
}
