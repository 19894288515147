@import "src/styles";

.shift-button {
    justify-content: left;
    margin: 1rem auto;
    border: 2px solid var(--c-sailor);
    width: 30rem;
    padding: 2.5rem 0 2.5rem 7rem;
    border-radius: .5rem;
    font-size: 1.3125rem;
    font-weight: var(--fw-regular);
    letter-spacing: .05rem;
    color: var(--c-white);

    background: var(--c-primary);
    background-image: url('../../images/play.svg');
    background-repeat: no-repeat;
    background-position-x: 2rem;
    background-position-y: 50%;

    &:hover, &:focus {
        color: var(--c-white);
    }
}