@import "src/styles";

.language-selector {
    display: flex;
    gap: 1rem;

    &__button {
        @extend %button;

        border: .125rem solid var(--c-sailor);
        border-radius: .5rem;
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        color: var(--c-white);

        &:hover {
            color: var(--c-white);
        }

        &:active {
            background-color: var(--c-secondary);
            border-color: var(--c-secondary);
            color: var(--c-primary);
        }

        &--active {
            background-color: var(--c-dark-blue);
            border-color: var(--c-white);
            color: var(--c-primary);
        }
    }

}
