@import "src/styles";

.dot {
    transition: background-color 0.15s ease-out 0s;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background: var(--c-sailor);

    &--is-filled {
        background: var(--c-white);
    }
}
