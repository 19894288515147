@import "button";
@import "flex";
@import "loader";
@import "page";

%text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
