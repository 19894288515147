@import "src/styles";

$component: skip-button;

.#{$component} {
    padding: 0 1rem;

    &.#{$component} {
        font-size: 1.25rem;
        color: var(--c-white);
    }

    &:active {
        color: var(--c-sailor);

        .#{$component}__icon {
            color: var(--c-sailor);
        }
    }

    .#{$component}__icon {
        @include icon-size(1.5rem);

        color: var(--c-white);
    }
}
