%page-back-button-position {
    position: absolute;
    bottom: 3rem;
    left: 2rem;
}

%page-skip-button-position {
    position: absolute;
    bottom: 3rem;
    right: 2rem;
}

%page-log-out-button-position {
    position: absolute;
    top: 3.75rem;
    right: 2.5rem;
}
