@mixin icon-size($size: 1rem) {
    max-width: $size;
    max-height: $size;
    width: $size;
    height: $size;
}

@mixin animate-scale-fade-in($nth-child: 0) {
    animation: scaleFadeIn .75s cubic-bezier(0,.54,.39,1) ($nth-child * .1s);
    animation-fill-mode: forwards;
    transform: scale(.9);
    transform-origin: 50% 50%;
    opacity: 0;
}

@mixin animate-scale-in($nth-child: 0) {
    animation: scaleIn .25s cubic-bezier(0,.54,.39,1) ($nth-child * .05s);
    animation-fill-mode: forwards;
    transform: scale(0);
    transform-origin: 50% 50%;
}
