@import "src/styles";

.check-spinner {
    display: flex;
    justify-content: center;

    &__circle {
        position: relative;
        margin: 1rem;
        border: 4px solid rgba(0, 0, 0, 0.2);
        border-left-color: var(--c-green);
        border-radius: 5rem;
        width: 10rem;
        height: 10rem;
        animation: spin 1.2s infinite linear;

        &--is-finished {
            border-color: var(--c-green);
            transition: border 500ms ease-out;
            animation: unset;
        }
    }

    &__check {
        display: none;

        &--is-checked {
            display: block;

            &:after {
                animation: check 800ms ease;
                transform: scaleX(-1) rotate(135deg);
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: 2rem;
            top: 5rem;
            transform-origin: left top;
            border-right: 5px solid var(--c-green);
            border-top: 5px solid var(--c-green);
            height: 5rem;
            width: 2.5rem;
        }
    }
}

