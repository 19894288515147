@keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(1turn) }
}

@keyframes check {
    0% {
        height: 0;
        width: 0;
    }

    20% {
        height: 0;
        width: 2.5rem;
    }

    40% {
        height: 5rem;
        width: 2.5rem;
    }

    100% {
        height: 5rem;
        width: 2.5rem;
    }
}

@keyframes scaleFadeIn {
    from { transform: scale(.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
}

@keyframes scaleIn {
    from { transform: scale(0); }
    to { transform: scale(1); }
}
