@import "src/styles";

.icon-component {
    height: 1rem;

    circle,
    path,
    polygon,
    polyline,
    rect {
        fill: currentColor;
    }

    .stroke {
        fill: none;
        stroke: currentColor;
    }
}
