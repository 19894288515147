@import "src/styles";

.meal-screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        margin-top: 3rem;
    }

    &__buttons-wrapper {
        display: flex;
        margin-top: 3.5rem;
    }

    &__meal-option {
        @extend %button--is-rectangular;

        & + & {
            margin-left: 1.5rem;
        }

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                @include animate-scale-in($i);
            }
        }
    }

    &__back-button {
        @extend %page-back-button-position;
    }
}
