@import "src/styles";

.inactive-screen {
    text-align: left;

    &__window {
        width: 100%;
        max-width: 40rem;
        padding: 4rem 3rem;
        color: var(--c-dark-grey);
        background: var(--c-white);
    }

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }

    &__intro,
    &__contact {
        margin-top: 2.5rem;
        font-size: .875rem;
        font-weight: var(--fw-medium);
    }

    &__contact {
        margin-top: 2rem;
    }

    &__log-out-button {
        @extend %page-log-out-button-position;
    }
}
