@import "src/styles";

$component: back-button;

.#{$component} {

    &.#{$component} {
        font-size: 1.25rem;
        color: var(--c-white);
    }

    &:active {
        color: var(--c-sailor);

        .#{$component}__icon {
            color: var(--c-sailor);
        }
    }

    .#{$component}__icon {
        @include icon-size(1.5rem);

        margin-right: 1.25rem;
        color: var(--c-white);
    }
}
