@import "src/styles";

.cache-buster {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 2rem;
    transform: translateY(100%);
    transition: transform .3s ease;

    &--is-revealed {
        transform: translateY(0);
    }

    &__card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: .5rem;
        width: 23rem;
        min-height: 3.125rem;
        padding: 1rem;
        background: white;
        overflow: hidden;

        & + & {
            margin-top: .625rem;
        }
    }

    &__description {
        font-weight: var(--fw-medium);
    }

    &__button {
        margin-left: 1rem;
        padding: .75rem 1rem;
    }
}
