@import "src/styles";

.button {
    @extend %button;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}
