@import "src/styles";

.clock-out-screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        @include animate-scale-fade-in();

        order: 3;
        margin-top: 2rem;
    }

    &__date,
    &__current-time {
        font-weight: var(--fw-regular);
        font-size: 1.25rem;
    }

    &__current-time {
        margin-left: .25rem;
    }

    &__current-time-label:after {
        margin: 0 .125rem;
    }

    &__date {
        order: 1;
        display: flex;
    }

    &__timer {
        order: 2;
        margin-top: 1rem;
    }

    &__clock-out-button-wrapper {
        @include animate-scale-fade-in();

        order: 4;
        margin-top: 2.5rem;
    }

    &__clock-out-button {
        border-radius: 50%;
        width: 8.75rem;
        height: 8.75rem;
        background: url('../../../images/stop.svg') rgba(var(--c-rgba-white), 0.4);
        opacity: .9;
    }

    &__log-out-button {
        @extend %page-log-out-button-position;
    }

    &__check-spinner {
        order: 4;
        margin-top: 2.5rem;
    }
}
